/* eslint-disable camelcase */
/* eslint-disable max-nested-callbacks */
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import { compact, groupBy, keys, omit, orderBy, round, template, uniqWith } from 'lodash'
import { Grid, useTheme } from '@mui/material'
import moment from 'moment'
import { MainButton, OrangeButton, RedButton, TextButton } from '../components/styled/buttons'
import AgriAction from '../agri/actions/AgriAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import { formatMilliers } from '../../../utils/StringUtil'
import CmsAction from '../cms/actions/CmsAction'
import { hasValue } from '../../../utils/NumberUtil'
import { getColorAndLabelWaterTurn, getIPSIconDesktop, getIPSLabel, getRestrictionIconV2 } from '../../../utils/AgriUtils'
import { darkRed, mainColor, textColor } from '../components/styled/theme'
import ContactAction from '../contact/actions/ContactAction'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import { POINT_STATUS_OBJ, STATION_TYPE_CONSTANT } from '../referencials/installations/constants/InstallationsConstants'
import { getSettingInt } from '../../../utils/FormUtils'
import { ArrowForwardIos, CancelOutlined, CheckCircleOutlined, Error, Help, KeyboardArrowLeft } from '@mui/icons-material'
import { AGRI, AGRI_RSEAU, CHRONICLES_CONSTANTS, EVENT_TYPE, SAMPLE_TYPES } from '../agri/constants/AgriConstants'
import RSEauPanel from '../waterManagement/RSEauPanel'
import { convertToRGB } from '../../../utils/ColorUtil'
import LoadingCard from '../components/cards/LoadingCard'
import { LightCard } from '../components/styled/grid'
import { DialogContentMUIDesktop, DialogMUI, DialogTitleMUIv2 } from '../components/styled/dialog'
import PointConsoDesktop from './PointConsoDesktop'
import PointEventsAppDesktop from './PointEventsAppDesktop'
import useApplicationSetting from '../../../utils/customHooks/useApplicationSetting'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../referencials/materiels/constants/MaterielConstants'
import ModalIndexEntry from '../components/ModalIndexEntry'
import PointRestrictionDesktop from './PointRestrictionDesktop'
import { useParams } from 'react-router'

const PointDashboardDesktop = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [contactsLoaded, setContactsLoaded] = useState(false)
    const [showCalendar, setShowCalendar] = useState(true)
    const [showConso, setShowConso] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [openModalRestrictions, setOpenModalRestrictions] = useState(false)
    const [situationsLoaded, setSituationsLoaded] = useState(false)
    const [openModalNewIndex, setOpenModalNewIndex] = useState(false)

    const {
        installation,
        variousMateriels,
        variousMatSituations,
        exploitation,
        exploitationVolumes,
        matEventsExploitation,
        watersheds,
        citiesIndex,
        managementUnitsRestrictions,
        exploitationWaterTurns,
        waterTurnsRestrictions,
        waterTurnsSlots,
        droughtSectorsRestrictions,
        applicationSettings,
        accountHabilitations,
        mapSituations,
        mapSituationStats,
        mapSituationResults,
        allLinkedStations,
    } = useSelector((store) => ({
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        exploitation: store.AgriReducer.exploitation,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        matEventsExploitation: store.MaterielReducer.matEventsExploitation,
        watersheds: store.ReferencialReducer.watersheds,
        citiesIndex: store.CityReducer.citiesIndex,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        applicationSettings: store.HomeReducer.applicationSettings,
        accountHabilitations: store.AccountReducer.accountHabilitations,
        mapSituations: store.InstallationsReducer.mapSituations,
        mapSituationStats: store.InstallationsReducer.mapSituationStats,
        mapSituationResults: store.InstallationsReducer.mapSituationResults,
        allLinkedStations: store.InstallationsReducer.allLinkedStations,
    }), shallowEqual)

    const dispatch = useDispatch()

    const theme = useTheme()
    const params = useParams()

    const idInstallation = useMemo(() => parseInt(params.id), [])
    const date = useMemo(() => moment().valueOf(), [])
    const year = useMemo(() => new Date().getFullYear(), [])
    const materielsTypeForIndex = useApplicationSetting('materielsTypeForIndex', s => s?.split(',')?.map(id => parseInt(id)))

    /* const deleteBookmark = (fav) => {
        dispatch(ReferencialAction.deleteBookmark(fav)).then(() => {
            dispatch(ReferencialAction.fetchBookmarks())
            ToastrAction.info(`Point ${installation.code} retiré des favoris`)
        })
    } */

    useEffect(() => {
        if (idInstallation && exploitation.idExploitation) {
            dispatch(AgriAction.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf())).then(() => {
                if (!exploitation.idExploitation) {
                    dispatch(AgriAction.fetchExploitation()).then(() => setDataLoaded(true))
                } else {
                    setDataLoaded(true)
                }
                dispatch(ReferencialAction.fetchBookmarks())
            })
        }
    }, [installation, exploitation])

    useEffect(() => {
        if (idInstallation) {
            dispatch(InstallationsAction.fetchInstallation(idInstallation)).then(() => {
                if (!exploitation.idExploitation) {
                    dispatch(AgriAction.fetchExploitation())
                }
            })
        }
    }, [idInstallation])

    useEffect(() => {
        if (exploitation.idExploitation) {
            dispatch(InstallationsAction.fetchInstallationsByExploitationId(exploitation.idExploitation)).then(() => {
                dispatch(InstallationsAction.fetchMapSituations('piezometry')).then(() => {
                    const map = mapSituations.find((m) => m.default)
                    if (map) {
                        dispatch(InstallationsAction.fetchMapSituationsStationStats('piezometry', map.id)).then(() => {
                            if (mapSituationStats?.length) {
                                dispatch(InstallationsAction.fetchMapSituationsResults('piezometry', map, mapSituationStats[0]?.date)).then(() => {
                                    dispatch(InstallationsAction.fetchAllLinkedStations(exploitation?.link_samplings?.map((l) => l.idInstallation), STATION_TYPE_CONSTANT.installation)).then(() => {
                                        setSituationsLoaded(true)
                                    })
                                })
                            } else {
                                setSituationsLoaded(true)
                            }
                        })
                    } else {
                        setSituationsLoaded(true)
                    }
                })
            })
        }
    }, [exploitation])

    useEffect(() => {
        dispatch(CmsAction.fetchCmsByCategory(5))
        dispatch(ReferencialAction.fetchManagementUnitsRestrictions())
        dispatch(AgriAction.fetchDroughtSectorsRestrictions())
        dispatch(ContactAction.fetchContacts()).then(() => setContactsLoaded(true))
        window.scrollTo(0, 0)
    }, [])


    /* const createBookmark = (fav, point) => {
        dispatch(ReferencialAction.createBookmark(fav)).then(() => {
            dispatch(ReferencialAction.fetchBookmarks())
            ToastrAction.success(`Point ${point.code} ajouté aux favoris.<br /> Il apparaitra en haut de votre liste des points de prélèvement`)
        })
    } */


    const getMateriels = () => {
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation && (!m.situationEndDate || m.situationEndDate > date) && (!m.situationDate || m.situationDate < date)) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode) && (!m.situationEndDate || m.situationEndDate > date) && (!m.situationDate || m.situationDate < date)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const materiels = variousMateriels.filter((c) => [...pumpsIds, ...countersIds].includes(c.id) && (!hasValue(c.administrator) || c.administrator === exploitation.operatorCode))
        return { pumpsIds, countersIds, materiels }
    }

    const hasHabilitation = hab => accountHabilitations.some(h => h.habilitation === hab)
    if (!dataLoaded || !contactsLoaded || !variousMatSituations.length || !variousMateriels.length || !exploitation.idExploitation) {
        return <LoadingCard />
    }

    const getState = () => {
        if (!situationsLoaded) {
            return <LoadingCard />
        }
        const station = allLinkedStations?.filter(s => s.id === installation.id && s.stationLinkedType === STATION_TYPE_CONSTANT.PIEZOMETRY)[0] || {}
        const situation = mapSituationResults?.filter(s => s.id === station.stationLinkedId)[0] || {}
        const ipsColor = situation?.trendColor
        const ipsLabel = situation?.trendLabel
        return (
            <Grid
                item
                container
                alignItems='center'
                flexWrap='nowrap'
                columnGap= '5px'
                fontSize='11px'
                lineHeight='16px'
                sx={{ width: 'auto', border: `1px solid ${ipsColor || 'black'}`, borderRadius: '5px', backgroundColor: `rgba(${convertToRGB(ipsColor || '#000000')}, 0.1)`, padding: `${theme.spacing(2)} ${theme.spacing(2)}` }}
            >
                <Grid item >{getIPSIconDesktop(ipsLabel, ipsColor)}</Grid>
                <Grid item container flexWrap='nowrap' columnGap={theme.spacing(2)} alignItems='center' sx={{ height: '100%' }}>
                    <Grid item color={ipsColor} >{i18n.level}</Grid>
                    <Grid item className='bold' color={ipsColor} >{getIPSLabel(ipsLabel)}</Grid>
                </Grid>
            </Grid>
        )
    }

    const { pumpsIds, countersIds, materiels } = getMateriels()
    const getMaterial = (idVariousMaterial) => {
        return variousMateriels.find(m => m.id === idVariousMaterial)
    }

    const getWarningCondition = () => {
        const ids = [...pumpsIds, ...countersIds]
        const materialsSeizableIndex = ids?.map(id => getMaterial(id))?.filter(m => materielsTypeForIndex?.includes(m?.materielType))
        if (materialsSeizableIndex?.length > 0) {
            const lastIndex = orderBy(exploitation.link_chronicles.filter((c) => materialsSeizableIndex.map(m => m.id).includes(c.idMat) && c.measureType === CHRONICLES_CONSTANTS.TYPE_INDEX), 'measureDate', 'desc')[0]
            if (lastIndex) {
                return { idMatLastChronicle: lastIndex.idMat, days: lastIndex.measureDate, important: true, estim: false }
            }
            const lastAffectIndex = orderBy(materiels.filter(m => materialsSeizableIndex.some(mat => mat.id === m.idVarious)), 'situationDate', 'desc')[0]
            if (lastAffectIndex) {
                return materialsSeizableIndex?.length === 1 ? { idMatLastChronicle: lastAffectIndex.idVarious, days: lastAffectIndex.situationDate, important: false, estim: false } : { days: lastAffectIndex.situationDate, important: false, estim: false }
            }
            return { important: false, estim: false }
        }
        const lastEstim = orderBy(exploitation.link_chronicles.filter((c) => ids.includes(c.idMat) && c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM), 'measureDate', 'desc')[0]
        if (lastEstim) {
            return { days: lastEstim.measureDate, important: true, estim: true }
        }
        const lastAffectEstim = orderBy(materiels.filter(m => materialsSeizableIndex.some(mat => mat.id !== m.idVarious)), 'situationDate', 'desc')[0]
        if (lastAffectEstim) {
            return { days: lastAffectEstim.situationDate, important: false, estim: true }
        }
        return { noIndex: true, important: false, }
    }

    // const linkPoint = exploitation.link_samplings.find((p) => p.idInstallation === idInstallation)
    // const exploitationMatIds = materiels.map(({ id }) => id)
    const volumeAuth = (exploitationVolumes || []).filter((v) => v.askedYear === year && v.idInstallation === idInstallation).reduce((acc, v) => acc + (v.authorizedVolume || 0), 0)
    const volumeConsumed = uniqWith((exploitation.link_conso_real || []).filter((c) => c.idInstallation === idInstallation && c.year === moment().year()).map(c => omit(c, 'idInstallation')), (a, b) => a.idMat === b.idMat && a.year === b.year && a.volume === b.volume).reduce((acc, v) => acc + (v.volume || 0), 0)
    const volumeLeft = (volumeAuth - volumeConsumed)
    const percentLeft = (volumeLeft / volumeAuth) * 100
    const sampleCharacteristics = installation?.link_sampleCharacteristics?.[0] || {}
    const typeRestriction = getSettingInt(applicationSettings, 'agriTypeRestriction') || 1
    const restriction = (() => {
        if (typeRestriction === 1) {
            return droughtSectorsRestrictions.find((dsR) => dsR.idSector === sampleCharacteristics.droughtSector && sampleCharacteristics.sampleType === dsR.resourceType) || { idRestriction: -1 }
        }
        return managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === installation.subManagementCode && sampleCharacteristics.sampleType === ugeR.resourceType) || managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === installation.managementCode && sampleCharacteristics.sampleType === ugeR.resourceType) || { idRestriction: -1 }
    })()
    const waterTurnsRestriction = waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction)
    const restrictionLabel = waterTurnsRestriction?.label || i18n.normale
    const restrictionColor = waterTurnsRestriction?.color || '#00AF64'
    const sampleTypeLabel = sampleCharacteristics?.sampleType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial
    const city = citiesIndex[installation.townCode] || {}
    const { color, max, timeNextSampling } = getColorAndLabelWaterTurn(waterTurnsRestrictions, exploitationWaterTurns, waterTurnsSlots, installation)
    const { idMatLastChronicle, days, important, estim, noIndex } = getWarningCondition()
    const stateCode = exploitation.link_samplings.find(linkPoint => linkPoint.idInstallation === idInstallation)?.stateCode
    const warning = stateCode !== POINT_STATUS_OBJ.USED ? true : noIndex || (days ? moment().diff(moment(days), 'days') > (365 * 3) : true)
    const isImportant = stateCode !== POINT_STATUS_OBJ.USED ? false : important
    const groupedEventsByMats = groupBy(matEventsExploitation.filter(({ idMateriel }) => [...pumpsIds, ...countersIds].includes(idMateriel)), 'idMateriel')
    const nbPannes = compact(keys(groupedEventsByMats).map((idMat) => {
        if (orderBy(groupedEventsByMats[idMat], 'updateDate', 'desc')[0]?.idType === EVENT_TYPE.FAILURE) {
            return true
        }
        return null
    })).length

    const getButtonIndexDeclaration = () => (
        warning ?
            (isImportant ?
                (<RedButton
                    noFullWidth
                    startIcon={<Error />}
                    sx={{
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '16px',
                        margin: 0
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        if (idMatLastChronicle) {
                            setOpenModalNewIndex(true)
                        } else {
                            dispatch(push(`/index/${idInstallation}/materiels/`))
                        }
                    }}
                >
                    {template(estim ? i18n.noEstimSinceXDays : i18n.noIndexSinceXDays)({ days: moment().diff(moment(days), 'days') })}
                </RedButton>)
                :
                (
                    <OrangeButton
                        noFullWidth
                        sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '16px',
                            margin: 0
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            if (idMatLastChronicle) {
                                setOpenModalNewIndex(true)
                            } else {
                                dispatch(push(`/index/${idInstallation}/materiels/`))
                            }
                        }}
                    >
                        {(estim || noIndex) ? i18n.enterEstimQuestion : i18n.enterIndexQuestion}
                    </OrangeButton>
                )
            )
            :
            (
                <MainButton noFullWidth sx={{ width: '10vw' }} onClick={() => dispatch(push(`/index/${idInstallation}/materiels`))}>
                    {i18n.enterIndex}
                </MainButton>
            )
    )

    return (
        <Grid
            container
            item
            xs={12}
            sx={{
                maxHeight: '100%',
                overflowY: 'hidden',
            }}
        >
            <Grid container item alignItems='center' justifyContent='flex-start' color={textColor}>
                <Grid item
                    sx={{ paddingRight: theme.spacing(3) }}
                    className='clickable'
                    onClick={() => window.history.back()}
                >
                    <KeyboardArrowLeft sx={{ fontSize: '30px' }}/>
                </Grid>
                <Grid item sx={{ fontSize: '22px', lineHeight: '28px' }} >{i18n.point} {':'} {installation.code}{','} {i18n.parcel} {installation?.link_geo?.[0]?.parcel} {installation?.link_geo?.[0]?.section}</Grid>
            </Grid>
            <Grid container item flexWrap='nowrap' alignItems='center' justifyContent='flex-start' sx={{ paddingTop: theme.spacing(3) }}>
                <Grid container item columnGap={theme.spacing(4)} flexWrap='nowrap'>
                    {getState()}
                    <Grid item container direction='column' fontSize={14} sx={{ width: 'auto' }}>
                        <Grid item sx={{ marginBottom: '8px' }}>{i18n.samplingResource}</Grid>
                        <Grid item className='bold'>{watersheds.find((w) => w.id === sampleCharacteristics.bvCode)?.name}</Grid>
                        <Grid item className='bold'>{sampleTypeLabel}</Grid>
                    </Grid>
                    <Grid item container direction='column' fontSize={14} sx={{ width: 'auto' }}>
                        <Grid item sx={{ marginBottom: '8px' }}>{i18n.localisation}</Grid>
                        <Grid item className='bold'>{city?.name ?? ''}</Grid>
                        <Grid item className='bold'>{installation?.location ?? ''}</Grid>
                    </Grid>
                </Grid>
                <Grid container item justifyContent='flex-end' >
                    {getButtonIndexDeclaration()}
                </Grid>
            </Grid>
            <Grid
                container
                item
                alignItems='center'
                justifyContent='space-between'
                sx={{
                    height: 'calc(100% - 8rem)',
                    paddingTop: theme.spacing(3),
                    overflowY: 'hidden'
                }}
            >
                <Grid container item xs={3.5} sx={{ height: '100%', overflowY: 'auto' }}>
                    <LightCard
                        item
                        container
                        justifyContent='space-between'
                    >
                        <Grid item xs={12} fontSize={24} sx={{ textDecoration: showCalendar && 'underline' }}>
                            {i18n.samplingPossibility}
                        </Grid>
                        <Grid container justifyContent='space-between' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px', margin: '16px 0' }}>
                            <Grid item xs={4} container direction='column' alignItems='flex-start'>
                                <Grid item className='bold' fontSize={12} sx={{ paddingBottom: '8px' }}>{i18n.situation}</Grid>
                                <Grid item sx={{ height: '24px', marginBottom: '8px' }}>{getRestrictionIconV2(waterTurnsRestriction?.level, { height: '24px', color: restrictionColor })}</Grid>
                                <Grid item fontSize={12}>{restrictionLabel}</Grid>
                            </Grid>
                            <Grid item xs={8} container direction='column' alignItems='flex-start'>
                                <Grid item className='bold' fontSize={12} sx={{ paddingBottom: '8px' }}>{i18n.waterTurn}</Grid>
                                <Grid item sx={{ height: '24px', marginBottom: '8px' }}>{max ? <CancelOutlined sx={{ color: darkRed }} /> : <CheckCircleOutlined sx={{ color }} /> }</Grid>
                                <Grid item fontSize={12}>{timeNextSampling ? template(i18n.nextSamplingInXHours)({ hours: timeNextSampling }) : ''}</Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent='flex-end'
                            alignItems='center'
                            sx={{ color: mainColor }}
                        >
                            <TextButton
                                noFullWidth={true}
                                sx={{ margin: 0 }}
                                onClick={() => {
                                    setShowCalendar(true)
                                    setShowConso(false)
                                    setShowDetails(false)
                                }}
                                endIcon={<ArrowForwardIos fontSize='20px'/>}
                            >
                                {i18n.seeCalendar}
                            </TextButton>
                        </Grid>
                    </LightCard>
                    <LightCard
                        item
                        container
                        justifyContent='space-between'
                        sx={{ marginTop: theme.spacing(2) }}
                    >
                        <Grid item xs={12} fontSize={24} sx={{ textDecoration: showConso && 'underline' }}>
                            {i18n.samplingVolume}
                        </Grid>
                        <Grid container justifyContent='space-between' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px', margin: '16px 0' }}>
                            <Grid item xs={4} container direction='column' alignItems='flex-start'>
                                <Grid item className='bold' fontSize={22} sx={{ height: '24px', marginBottom: '8px' }}>{formatMilliers(volumeAuth)}</Grid>
                                <Grid item fontSize={12}>{i18n.authorizedVolume}</Grid>
                            </Grid>
                            <Grid item xs={7} container direction='column' alignItems='flex-start'>
                                <Grid item className='bold' fontSize={22} sx={{ height: '24px', marginBottom: '8px' }}>{volumeAuth ? `${percentLeft <= 0 ? 0 : round(percentLeft)}%` : '-'}</Grid>
                                <Grid item fontSize={12}>{i18n.remainingVolume}</Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent='flex-end'
                            alignItems='center'
                            sx={{ color: mainColor }}
                            className='bold'
                        >
                            <TextButton
                                noFullWidth={true}
                                sx={{ margin: 0 }}
                                onClick={() => {
                                    setShowConso(true)
                                    setShowCalendar(false)
                                    setShowDetails(false)
                                }}
                                endIcon={<ArrowForwardIos fontSize='20px'/>}
                            >
                                {i18n.seeConsumption}
                            </TextButton>
                        </Grid>
                    </LightCard>
                    <LightCard
                        item
                        container
                        justifyContent='space-between'
                        sx={{ marginTop: theme.spacing(2) }}
                    >
                        <Grid item xs={12} fontSize={24} sx={{ textDecoration: showDetails && 'underline' }}>
                            {i18n.materialsState}
                        </Grid>
                        <Grid container direction='column' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px', margin: '16px 0 8px' }}>
                            <Grid item className='bold' fontSize={22} sx={{ height: '24px', marginBottom: '8px' }}>{nbPannes}</Grid>
                            <Grid item fontSize={12}>{i18n.breakdownInProgress}</Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ color: mainColor }}
                            className='bold'
                        >
                            <Grid item>
                                <TextButton
                                    sx={{ fontSize: '12px', padding: '4px 12px' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        dispatch(push(`/installations/point/${idInstallation}/events/materiels`))
                                    }}
                                >
                                    {i18n.reportIncident}
                                </TextButton>
                            </Grid>
                            <Grid item>
                                <TextButton
                                    noFullWidth={true}
                                    sx={{ margin: 0 }}
                                    onClick={() => {
                                        setShowDetails(true)
                                        setShowConso(false)
                                        setShowCalendar(false)
                                    }}
                                    endIcon={<ArrowForwardIos fontSize='20px'/>}
                                >
                                    {i18n.seeDetails}
                                </TextButton>
                            </Grid>
                        </Grid>
                    </LightCard>
                </Grid>
                <Grid container item xs={8.35} sx={{ height: '100%', overflowY: 'auto' }}>
                    <LightCard sx={{ width: '100%' }}>
                        {showCalendar &&
                            <Grid item container>
                                <Grid container item alignItems='center' justifyContent='space-between' color={textColor} sx={{ fontSize: '22px', lineHeight: '28px' }}>
                                    {i18n.waterTurnsCalendar}
                                    <Help className='clickable' sx={{ color: mainColor }} onClick={() => setOpenModalRestrictions(true) } />
                                </Grid>
                                <PointRestrictionDesktop {...props} sx={{ paddingTop: theme.spacing(2) }}/>
                            </Grid>
                        }
                        {showConso &&
                            <Grid item container>
                                <Grid container item alignItems='center' justifyContent='flex-start' color={textColor} sx={{ fontSize: '22px', lineHeight: '28px' }}>
                                    {i18n.consumptionMonitoring}
                                </Grid>
                                <PointConsoDesktop {...props} sx={{ paddingTop: theme.spacing(2) }}/>
                            </Grid>
                        }
                        {showDetails &&
                            <Grid item container>
                                <Grid container item alignItems='center' justifyContent='flex-start' color={textColor} sx={{ fontSize: '22px', lineHeight: '28px' }}>
                                    {i18n.materialsState}
                                </Grid>
                                <PointEventsAppDesktop {...props} sx={{ paddingTop: theme.spacing(2) }}/>
                            </Grid>
                        }
                    </LightCard>
                </Grid>
            </Grid>
            {hasHabilitation(AGRI) && hasHabilitation(AGRI_RSEAU) && (
                <RSEauPanel idInstallation={idInstallation} />
            )}
            {openModalRestrictions && (
                <DialogMUI open={openModalRestrictions} fullScreen={false}>
                    <DialogTitleMUIv2 sx={{ paddingBottom: 0 }} onClick={() => setOpenModalRestrictions(false)}>
                        {i18n.waterTurnsRestrictions}
                    </DialogTitleMUIv2>
                    <DialogContentMUIDesktop>
                        {waterTurnsRestrictions.map((r) => (
                            <Grid container sx={{ padding: '8px 0', color: mainColor }}>
                                <Grid item sx={{ backgroundColor: r.color || 'grey', display: 'inline-block', width: '20px', height: '20px', margin: '16px' }}>&nbsp;</Grid>
                                <Grid item xs='auto' container direction='column'>
                                    <b>{`${r.label} - ${i18n.level} ${r.level}`}</b>
                                    <p>{r.description}</p>
                                </Grid>
                                <Grid item xs={12} sx={{ marginLeft: '16px', marginBottom: '10px' }}>{r.comment}</Grid>
                            </Grid>
                        ))}
                    </DialogContentMUIDesktop>
                </DialogMUI>
            )}
            {openModalNewIndex && <ModalIndexEntry
                point={installation}
                openModalNewIndex={openModalNewIndex}
                setOpenModalNewIndex={setOpenModalNewIndex}
                material={getMaterial(idMatLastChronicle)}
            />}
        </Grid>
    )
}

export default PointDashboardDesktop
