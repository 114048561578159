module.exports = {
    RECEIVE_EXPLOITATION: 'RECEIVE_EXPLOITATION',
    RECEIVE_EXPLOITATIONS_DATA: 'RECEIVE_EXPLOITATIONS_DATA',
    RECEIVE_EXPLOITATION_VOLUMES: 'RECEIVE_EXPLOITATION_VOLUMES',
    RECEIVE_DECLARATION: 'RECEIVE_DECLARATION',
    RECEIVE_SURVEY_VOLUMES_EXPLOITATION: 'RECEIVE_SURVEY_VOLUMES_EXPLOITATION',
    RECEIVE_DECLARATIONS_STATS_EXPLOITATION: 'RECEIVE_DECLARATIONS_STATS_EXPLOITATION',
    RECEIVE_DECLARATIONS: 'RECEIVE_DECLARATIONS',
    RECEIVE_DECLARATION_CONTRIBUTORS: 'RECEIVE_DECLARATION_CONTRIBUTORS',
    RECEIVE_ENQUETE: 'RECEIVE_ENQUETE',
    RECEIVE_ENQUETES: 'RECEIVE_ENQUETES',
    RECEIVE_OPERATOR: 'RECEIVE_OPERATOR',
    RECEIVE_OPERATORS: 'RECEIVE_OPERATORS',
    RECEIVE_MODES_IRRIGATIONS: 'RECEIVE_MODES_IRRIGATIONS',
    RECEIVE_DROUGHT_SECTORS_RESTRICTIONS: 'RECEIVE_DROUGHT_SECTORS_RESTRICTIONS',
    RECEIVE_RSEAU_DATA: 'RECEIVE_RSEAU_DATA',
    RECEIVE_CALCULATED_VOLUME: 'RECEIVE_CALCULATED_VOLUME',
    RECEIVE_SURVEY: 'RECEIVE_SURVEY',
    RECEIVE_SURVEYS: 'RECEIVE_SURVEYS',
    RECEIVE_WATERTURNS_RESTRICTIONS: 'RECEIVE_WATERTURNS_RESTRICTIONS',
    RECEIVE_WATERTURNS_EXPLOITATION: 'RECEIVE_WATERTURNS_EXPLOITATION',
    RECEIVE_WATERTURNS: 'RECEIVE_WATERTURNS',
    RECEIVE_WATERTURNS_SLOTS: 'RECEIVE_WATERTURNS_SLOTS',
    RECEIVE_TANKS_TYPES: 'RECEIVE_TANKS_TYPES',
    DECLA_POINTS_ADVANCEMENT: 'DECLA_POINTS_ADVANCEMENT',
    CHRONICLES_CONSTANTS: {
        TYPE_ESTIM: 1,
        TYPE_INDEX: 2,
        TYPE_EVENT: 3,
    },
    EXPLOITATION_STATION_TYPE: 10,
    SAMPLE_TYPES: {
        UNDERGROUND: 1,
        SUPERFICIAL: 2,
    },
    RESTRICTION_COLOR: '#00AF64',
    LOW_WATER_COLOR: '#FFF4A5',
    NOT_LOW_WATER_COLOR: '#CDF5FF',
    RESTRICTION_RESOURCE_TYPE: {
        DROUGHT_SECTORS: 1,
        MANAGEMENT_UNITS: 2,
    },
    SURVEY_TYPE: {
        ANNUAL_SURVEY: 1,
        INTERMEDIATE_SURVEY: 2,
    },
    USAGES_CATEGORY: {
        AGRI: 1,
        NOT_AGRI: 2
    },
    MAT_CATEGORY: {
        PUMP: 1,
        COUNTER: 2,
    },
    TANK_FILLING_PERIOD: {
        UNKNOWN: 0,
        ANNUAL: 1,
        SEASONAL: 2,
    },
    EVENT_TYPE: {
        FAILURE: 2,
        REACTIVATION: 3,
    },
    // AGRI
    AGRI: 'AGRI',
    AGRI_DOCUMENTS: 'AGRI_DOCUMENTS',
    AGRI_DOCUMENTS_LABEL: 'agriDocuments',
    AGRI_SURVEYS: 'AGRI_SURVEYS',
    AGRI_SURVEYS_LABEL: 'agriSurveys',
    AGRI_TERRITORY: 'AGRI_TERRITORY',
    AGRI_TERRITORY_LABEL: 'agriTerritory',
    AGRI_RSEAU: 'AGRI_RSEAU',
    AGRI_RSEAU_LABEL: 'RSEau',

    // Offline
    UPDATE_EXPLOITATION_CHRONICLES: 'UPDATE_EXPLOITATION_CHRONICLES',

    // Declaration
    DECLARATION_STATUS: {
        NOT_START: -1,
        STARTING: 0,
        ONGOING: 2,
        SENT: 3,
        VALIDATED: 4,
    },
    DECLARATION_CONTACT_TYPE: {
        CONTRIBUTOR: 1,
        CONTACT: 2,
    },
    DECLARATION_STRUCTURE_TYPE: {
        LEGAL_REP: -1,
        INDIV_STRUCT: 1,
    },
    DECLARATION_LINK_TYPE: {
        PUMP_COUNTER: 1,
        COUNTER_PUMP: 2,
    },
    POINT_ADVANCEMENT_DECLARATION: {
        TO_COMPLETE: 1,
        ONGOING: 0,
        VALIDATED: 2,
    },
    POINT_STATUS_DECLARATION: {
        USED: 1,
        REMOVED: 2,
        ADJOURNED: 3,
    },
    DECLA_LAST_STEP: {
        PERSONNAL_INFO_STEP: 1,
        CONTACTS_STEP: 2,
        POINTS_STEP: 3,
        COMMENTS_STEP: 4,
    },
    ENTRY_SETTINGS_FREQUENCY: {
        WEEKLY: 1,
        MONTHLY: 2,
    },
}
