import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { InputLabel, Select, MenuItem, Grid } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { MainButton, TextButton } from '../../../components/styled/buttons'
import { FormControlRow, InputRow } from '../../../components/styled/inputs'
import DtoContact from '../../../referencials/dto/DtoContact'
import ToastrAction from '../../../components/toasters/ToastrAction'
import { isValidEmail, isValidPhone, isValidZIPCode } from '../../../../../utils/FormUtils'
import { DialogContentMUIDesktop, DialogMUI, DialogTitleMUIv2 } from '../../../components/styled/dialog'
import { darkTextColor } from '../../../components/styled/theme'

const ModalContact = ({ contact, type, onSave, onDelete, onClose, showLegalRepresentative, isOpen, defaultLegalRepresentative }) => {
    const {
        cities,
        citiesIndex,
        codesSandre,
    } = useSelector((store) => ({
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        codesSandre: store.ReferencialReducer.codesSandre,
    }), shallowEqual)

    const [commune, setCommune] = useState(citiesIndex[Number(contact?.cityCode)] ?? undefined)
    const [newContact, setNewContact] = useState(contact ?? new DtoContact({}))
    const [newType, setnewType] = useState(type ?? undefined)

    const isUnvalidEmail = useMemo(() => newContact?.email?.length > 0 && !isValidEmail(newContact?.email), [newContact?.email])
    const isUnvalidMobile = useMemo(() => newContact?.mobile?.length > 0 && !isValidPhone(newContact?.mobile), [newContact?.mobile])
    const isUnvalidPhoneTel = useMemo(() => newContact?.phoneTel?.length > 0 && !isValidPhone(newContact?.phoneTel), [newContact?.phoneTel])
    const isUnvalidZIPCode = useMemo(() => !isValidZIPCode(newContact?.postalCode), [newContact?.postalCode])
    const isUnvalidCity = useMemo(() => !commune, [commune])
    const isUnvalidType = useMemo(() => !newType, [newType])
    const isUnvalidName = useMemo(() => !newContact?.name?.length, [newContact?.name])
    const isUnvalidAddress = useMemo(() => newContact.road?.length > 35, [newContact.road])

    const citiesFiltered = useMemo(() => (sortBy(cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(String(newContact.postalCode)))), 'name')), [newContact.postalCode, cities])
    const handleCommuneChange = (value) => {
        const newCommune = citiesIndex[value]
        setCommune(newCommune)
        if (newCommune?.link_postalCode && newCommune?.link_postalCode?.length) {
            setNewContact({ ...newContact, postalCode: newCommune.link_postalCode[0], cityCode: String(value) })
        } else {
            setNewContact({ ...newContact, postalCode: newContact.postalCode || '', cityCode: String(value) })
        }
    }

    const handleZIPCodeChange = (value) => {
        setNewContact({ ...newContact, postalCode: value })
        setCommune('')
    }

    const handlePhoneChange = (newPhoneNumber, phone) => {
        if (phone === 'mobile' && ((newContact?.mobile?.length < 10 || newPhoneNumber?.length < newContact?.mobile?.length) || !newContact?.mobile)) {
            setNewContact({ ...newContact, mobile: newPhoneNumber })
        } else if (phone === 'phoneTel' && ((newContact?.phoneTel?.length < 10 || newPhoneNumber?.length < newContact?.phoneTel?.length) || !newContact?.phoneTel)) {
            setNewContact({ ...newContact, phoneTel: newPhoneNumber })
        }
    }

    const onSaveContact = () => {
        if (isUnvalidCity || isUnvalidEmail || isUnvalidPhoneTel || isUnvalidMobile || isUnvalidZIPCode || isUnvalidName || isUnvalidType || isUnvalidAddress) {
            ToastrAction.error(i18n.fillAllFields)
        } else {
            onSave(newContact, newType?.code, type?.code)
            onClose()
        }
    }

    const typesContributors = sortBy(codesSandre.filter((c) => c.field === 'EXPLOITATIONS.TYPEINTERVENANT'), 'code')
    const typesContributorsWithLegalRep = [{ code: -1, name: i18n.legalRepresentative }, ...typesContributors]
    return (
        <DialogMUI open={isOpen}>
            <DialogTitleMUIv2 onClick={() => onClose()}>
                {contact ? i18n.changeContact : i18n.createNewContact}
            </DialogTitleMUIv2>
            <DialogContentMUIDesktop>
                <Grid
                    container
                    alignItems='flex-start'
                    justifyContent='space-between'
                    sx={{
                        paddingTop: '0.5vh'
                    }}
                >
                    <Grid item xs={5.9}>
                        <FormControlRow
                            required
                            variant='outlined'
                        >
                            <InputLabel>{i18n.type}</InputLabel>
                            <Select
                                id='type'
                                value={newType?.code || null}
                                label={i18n.type}
                                onChange={(e) => setnewType({ ...newType, code: e.target.value })}
                                error={isUnvalidType}
                                helperText={isUnvalidType ? i18n.unvalidType : ''}
                            >
                                {showLegalRepresentative ? (
                                    typesContributorsWithLegalRep.map((c) => (
                                        <MenuItem value={c.code}>{c.name}</MenuItem>
                                    ))
                                ) : (
                                    typesContributors.map((c) => (
                                        <MenuItem value={c.code}>{c.name}</MenuItem>
                                    ))
                                )}
                            </Select>
                        </FormControlRow>
                    </Grid>
                    <Grid item xs={5.9}>
                        <InputRow
                            id='name'
                            label={i18n.name}
                            type='text'
                            value={newContact?.name || null}
                            onChange={(e) => setNewContact({ ...newContact, name: e.target.value })}
                            variant='outlined'
                            required
                            error={isUnvalidName}
                            helperText={isUnvalidName ? i18n.unvalidName : ''}
                        />
                    </Grid>
                    <Grid item xs={5.9}>
                        <InputRow
                            item
                            id='address'
                            label={i18n.address}
                            type='text'
                            value={newContact?.road || null}
                            onChange={(e) => setNewContact({ ...newContact, road: e.target.value })}
                            variant='outlined'
                            error={isUnvalidAddress}
                            helperText={isUnvalidAddress ? i18n.tooLongAddress : ''}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputRow
                            item
                            id='postalCode'
                            label={i18n.postalCode}
                            type='code'
                            InputProps={{ inputProps: { min: 0, max: 99999 } }}
                            value={newContact?.postalCode || null}
                            onChange={(event) => handleZIPCodeChange(event.target.value) }
                            variant='outlined'
                            required
                            error={isUnvalidZIPCode}
                            helperText={isUnvalidZIPCode ? i18n.unvalidZIPCode : ''}
                        />
                    </Grid>
                    <Grid item xs={3.8}>
                        <Autocomplete
                            id='city'
                            options={citiesFiltered}
                            getOptionLabel={(option) =>
                                option.name ? option.name : ''
                            }
                            value={commune || null}
                            onChange={(e, value) => handleCommuneChange(value ? value.code : '')}
                            noOptionsText={i18n.noMatch}
                            renderInput={(params) => (
                                <InputRow
                                    {...params}
                                    label={i18n.city}
                                    variant='outlined'
                                    required
                                    error={isUnvalidCity}
                                    helperText={isUnvalidCity ? i18n.unvalidCity : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2.9}>
                        <InputRow
                            item
                            id='phoneTel'
                            label={i18n.phoneTel}
                            onChange={(e) => handlePhoneChange(e.target.value, 'phoneTel')}
                            type='text'
                            value={newContact?.phoneTel || null}
                            variant='outlined'
                            error={isUnvalidPhoneTel}
                            helperText={isUnvalidPhoneTel ? i18n.unvalidPhone : ''}
                        />
                    </Grid>
                    <Grid item xs={2.9}>
                        <InputRow
                            item
                            id='mobile'
                            label={i18n.phoneMobile}
                            onChange={(e) => handlePhoneChange(e.target.value, 'mobile')}
                            type='text'
                            value={newContact?.mobile || null}
                            variant='outlined'
                            error={isUnvalidMobile}
                            helperText={isUnvalidMobile ? i18n.unvalidPhone : ''}
                        />
                    </Grid>
                    <Grid item xs={5.9}>
                        <InputRow
                            item
                            id='email'
                            label={i18n.email}
                            type='text'
                            value={newContact?.email || null}
                            onChange={(e) => setNewContact({ ...newContact, email: e.target.value })}
                            variant='outlined'
                            error={isUnvalidEmail}
                            helperText={isUnvalidEmail ? i18n.unvalidEmail : ''}
                        />
                    </Grid>
                </Grid>
                <Grid item sx={{ fontSize: '14px', lineHeight: '16px', color: darkTextColor }}>{i18n.requiredField}</Grid>
                <Grid container justifyContent='center' sx={{ columnGap: '2vw' }}>
                    <TextButton noFullWidth sx={{ width: 'auto' }} onClick={!!contact && !defaultLegalRepresentative ? () => onDelete(contact, type.code) : () => onClose() }>{!!contact && !defaultLegalRepresentative ? i18n.deleteContact : i18n.cancel }</TextButton>
                    <MainButton noFullWidth sx={{ width: 'auto' }} onClick={() => onSaveContact()}>{i18n.saveContact}</MainButton>
                </Grid>
            </DialogContentMUIDesktop>
        </DialogMUI>
    )
}

ModalContact.propTypes = {
    contact: PropTypes.instanceOf(DtoContact),
    type: PropTypes.instanceOf(PropTypes.shape({})),
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    showLegalRepresentative: PropTypes.bool,
    isOpen: PropTypes.bool,
    defaultLegalRepresentative: PropTypes.bool,
}


export default ModalContact
