'use strict'
import { push } from '@lagunovsky/redux-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    LOGIN_FAIL,
    DISCONNECTED,
    RECEIVE_CURRENT_LOCATION,
    RECEIVE_WATCHID,
    ACTION,
    MENU,
    STATION_RANGE,
    POPUP,
    FULL_RESET,
    TITLE,
    MENU_RESET,
    MEDEAU_TOKEN,
    MEDEAU_LOGIN,
    RECEIVE_ALL_CGU,
    RECEIVE_DATE_VALID_CGU,
    RECEIVE_PARTENAIRE_CMS,
    RECEIVE_CGU_CMS,
    RECEIVE_APPLICATION_SETTINGS,
    RECEIVE_CMS_HOME,
    RECEIVE_CMS_LOGO,
    RECEIVE_ALL_USERS_LIGHT,
    RECEIVE_ARRESTS,
    CREDENTIALS,
} from '../constants/HomeConstants'
import AppStore from '../../../store/AppStore'
import ApplicationConf from '../../../conf/ApplicationConf'
import {
    checkAuth,
    getAuthorization,
    removeToken,
    getJson,
    checkError,
    getAuthorizationLogin,
    checkAuthV2,
    catchError,
    aquaFetchV2,
} from '../../../utils/ActionUtils'
import ToastrAction from '../../online/components/toasters/ToastrAction'
import DtoCMSEvent from '../../online/cms/dto/DtoCMSEvent'
import LogAction from '../../../utils/log/actions/LogAction'

const HomeAction = {
    loginFail(message) {
        return { type: LOGIN_FAIL, message }
    },
    watchId(id) {
        return { type: RECEIVE_WATCHID, watchId: id }
    },
    disconnected(message) {
        return { type: DISCONNECTED, message }
    },
    receiveCurrentLocation(position) {
        return { type: RECEIVE_CURRENT_LOCATION, position }
    },
    setStationRange(range) {
        localStorage.setItem(STATION_RANGE, range)
    },

    login(login, password) {
        const basicAuth = btoa(`${login}:${password}`)
        return () => {
            return fetch(ApplicationConf.login(), {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${basicAuth}`,
                    Module: 'MEDEAU',
                    Platform: 'WEB',
                },
                body: JSON.stringify({
                    module: 'MEDEAU',
                })
            })
                .then(response => checkAuthV2(response, {
                    401: () => {
                        ToastrAction.error(i18n.incorrectIdOrPassword, true)
                        return false
                    },
                }))
                .then(getJson)
                .then((json) => {
                    localStorage.setItem(MEDEAU_LOGIN, login)
                    localStorage.setItem(CREDENTIALS, basicAuth)
                    localStorage.setItem(MEDEAU_TOKEN, json)
                })
                .catch(e => {
                    LogAction.logError(`${i18n.fetchError + i18n.connexion} : ${e}`)
                    ToastrAction.error(i18n.fetchError + i18n.connexion)
                })
        }
    },

    logout() {
        return (dispatch) => {
            return fetch(ApplicationConf.logout(), {
                method: 'POST',
                headers: getAuthorization(),
            }).then((response) => {
                removeToken()
                dispatch(push('/login')).then(() => {
                    dispatch(HomeAction.fullReset())
                })
                if (response.status !== 200) {
                    throw response.statusText
                }
            })
        }
    },

    resetPassword(login, redirectLogin = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.resetPassword(), {
                method: 'POST',
                body: JSON.stringify({ login, module: 'MOBILE' }),
            })
                .then(checkError)
                .then((json) => {
                    if (json.status !== 200) {
                        ToastrAction.error(i18n.incorrectId, true)
                        return json
                    }
                    if (redirectLogin) {
                        dispatch(push('/login'))
                    }
                    return null
                })
                .catch((e) => {
                    LogAction.logError(`${i18n.fetchError + i18n.retrievePassword} : ${e}`)
                    ToastrAction.error(i18n.fetchError + i18n.retrievePassword, true)
                })
        }
    },

    updatePassword(password, token) {
        return () => {
            return fetch(ApplicationConf.resetPassword(), {
                method: 'PUT',
                body: JSON.stringify({ password, token })
            })
                .then(checkError)
                .catch(e => {
                    LogAction.logError(`${i18n.fetchError + i18n.newPassword} : ${e}`)
                })
        }
    },

    verifyResetCode(login, resetCode) {
        return () => {
            return fetch(ApplicationConf.verifyResetCode(), {
                method: 'POST',
                body: JSON.stringify({ login, resetCode })
            })
                .then(response => checkAuthV2(response, {
                    404: () => {
                        ToastrAction.error(i18n.unableToResetPassword)
                        return false
                    },
                    429: () => {
                        ToastrAction.error(i18n.error429)
                        return false
                    },
                    200: () => {
                        ToastrAction.success(i18n.valideCode)
                        return true
                    }
                }))
                .then(response => {
                    return response
                })
                .catch(e => {
                    LogAction.logError(`${i18n.fetchError + i18n.retrievePassword} : ${e}`)
                    ToastrAction.error(i18n.fetchError + i18n.retrievePassword)
                })
        }
    },

    receiveUsersLight(users) {
        return { type: RECEIVE_ALL_USERS_LIGHT, users }
    },

    promiseUsersLight() {
        return fetch(ApplicationConf.user.usersLight(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },
    fetchUsersLight() {
        return (dispatch) => {
            return HomeAction.promiseUsersLight()
                .then(json => {
                    dispatch(HomeAction.receiveUsersLight(json))
                })
        }
    },

    setAction(action) {
        return { type: ACTION, action }
    },
    setMenu(menu) {
        return { type: MENU, menu }
    },
    setPopup(popup) {
        return { type: POPUP, popup }
    },
    setTitle(title) {
        const storeTitleHash = AppStore.getState()
            .HomeReducer.title.map((obj) => obj.title)
            .join()
        const titleHash = title.map((obj) => obj.title).join()
        if (storeTitleHash !== titleHash) {
            return { type: TITLE, title }
        }
        return { type: TITLE, title }
    },
    resetMenu(menu) {
        return { type: MENU_RESET, menu }
    },
    fullReset() {
        return (dispatch) => {
            dispatch({ type: FULL_RESET })
        }
    },
    getAllCGU() {
        return (dispatch) => {
            return fetch(ApplicationConf.cgu.getAll(), {
                method: 'GET',
            })
                .then((response) => response.json())
                .then((json) => {
                    dispatch(HomeAction.receiveCGU(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    getDateValidCGU(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.cgu.getDateValidCGU(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then((json) => {
                    dispatch(HomeAction.receiveDateValidCGU(json.date))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    sendDateCgu(postDateCGU) {
        return () => {
            return fetch(ApplicationConf.cgu.sendDateCgu(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(postDateCGU),
            }).catch((err) => {
                LogAction.logError(`${i18n.createError} : ${err}`)
            })
        }
    },

    receiveCGU(cgu) {
        return { type: RECEIVE_ALL_CGU, cgu }
    },

    receiveDateValidCGU(date) {
        return { type: RECEIVE_DATE_VALID_CGU, date }
    },

    receiveCmsPartenaire(cms) {
        return { type: RECEIVE_PARTENAIRE_CMS, cms }
    },

    getCmsPartenaire() {
        return (dispatch) => {
            return fetch(ApplicationConf.cms.getByCategory(2), {
                method: 'GET',
                headers: getAuthorizationLogin(),
            })
                .then(getJson)
                .then((json) => {
                    dispatch(HomeAction.receiveCmsPartenaire(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    receiveCGUFromCms(cmsCGU) {
        return { type: RECEIVE_CGU_CMS, cmsCGU }
    },

    getCGUFromCms() {
        return (dispatch) => {
            return fetch(ApplicationConf.cms.getByCategory(3), {
                method: 'GET',
                headers: getAuthorizationLogin(),
            })
                .then(getJson)
                .then((json) => {
                    dispatch(HomeAction.receiveCGUFromCms(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    receiveApplicationSettings(applicationSettings) {
        return { type: RECEIVE_APPLICATION_SETTINGS, applicationSettings }
    },

    fetchApplicationSettings() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.user.applicationSettings(), {
                method: 'GET',
                headers: getAuthorizationLogin()
            }, { defaultResult: getState().HomeReducer.applicationSettings })
                .then(json => {
                    dispatch(HomeAction.receiveApplicationSettings(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.settings} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.settings)
                })
        }
    },

    receiveCMSHome(cmsHome) {
        return { type: RECEIVE_CMS_HOME, cmsHome }
    },

    fetchCMSHome(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.cms.get(id), {
                method: 'GET',
                headers: getAuthorizationLogin(),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    dispatch(HomeAction.receiveCMSHome(json))
                    return new DtoCMSEvent(json)
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.article} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.article)
                })
        }
    },

    receiveCMSLogo(cmsLogo) {
        return { type: RECEIVE_CMS_LOGO, cmsLogo }
    },

    fetchCMSLogo(id = 39) {
        return (dispatch) => {
            return fetch(ApplicationConf.cms.get(id), {
                method: 'GET',
                headers: getAuthorizationLogin(),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    dispatch(HomeAction.receiveCMSLogo(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.article} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.article)
                })
        }
    },

    receiveArrests: arrests => ({ type: RECEIVE_ARRESTS, arrests }),

    fetchArrests(departments = '') {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.arrest.get(departments), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().HomeReducer.arrests })
                .then((json = []) => {
                    dispatch(HomeAction.receiveArrests(json))
                })
        }
    },

    sendNotif(notificationContent, logins) {
        return () => {
            return fetch(ApplicationConf.user.sendNotif(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ notificationContent, logins })
            }).then(res => {
                if (res.status === 200) {
                    ToastrAction.success(i18n.entryValidated)
                } else {
                    throw new Error('')
                }
            }).catch(catchError)
        }
    },

    checkUrl: (url, callback = () => {}) => async () => {
        return await fetch(url, { method: 'HEAD' })
            .then(res => {
                callback(res.ok)
                return res.ok
            })
            .catch(() => {
                callback(false)
                return false
            })
    }
}

export default HomeAction
