import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Autocomplete, Grid } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { sortBy } from 'lodash'
import { isValidEmail, isValidPhone, isValidSiret, isValidZIPCode } from '../../../../../utils/FormUtils'
import DtoIntervenant from '../../../referencials/dto/DtoIntervenant'
import DtoIntervenantDeclaration from '../../../agri/dto/enquete/DtoIntervenantDeclaration'
import { deepEqual } from '../../../../../utils/ObjectUtils'
import AgriAction from '../../../agri/actions/AgriAction'
import { DECLA_LAST_STEP, DECLARATION_CONTACT_TYPE, DECLARATION_STATUS } from '../../../agri/constants/AgriConstants'
import { darkTextColor, mainColor } from '../../../components/styled/theme'
import { InputRow } from '../../../components/styled/inputs'
import { MainButton } from '../../../components/styled/buttons'
import { formatPhone } from '../../../../../utils/StringUtil'
import { SANDRE } from '../../../referencials/constants/ReferencialConstants'

const InfosValidation = ({
    readMode,
    setValidationStep,
}) => {
    const {
        contributor,
        citiesIndex,
        cities,
        declaration,
        declarationContributors,
        exploitation,
        codesSandre,
    } = useSelector((store) => ({
        contributor: store.ReferencialReducer.contributor,
        citiesIndex: store.CityReducer.citiesIndex,
        cities: store.CityReducer.cities,
        declaration: store.AgriReducer.declaration,
        declarationContributors: store.AgriReducer.declarationContributors,
        exploitation: store.AgriReducer.exploitation,
        codesSandre: store.ReferencialReducer.codesSandre,
    }), shallowEqual)

    const [commune, setCommune] = useState('')
    const [updateContributor, setUpdateContributor] = useState(contributor)
    const [editMode, setEditMode] = useState(!readMode)

    const isUnvalidEmail = useMemo(() => editMode && updateContributor?.email?.length > 0 && !isValidEmail(updateContributor?.email), [updateContributor.email])
    const isUnvalidPhone = useMemo(() => editMode && updateContributor?.mobile?.length > 0 && !isValidPhone(updateContributor?.mobile), [updateContributor.mobile])
    const isUnvalidZIPCode = useMemo(() => editMode && !isValidZIPCode(updateContributor?.postalCode), [updateContributor.postalCode])
    const isUnvalidCity = useMemo(() => editMode && !commune, [commune])
    const isUnvalidSiret = useMemo(() => editMode && !isValidSiret(updateContributor?.siret), [updateContributor.siret])
    const isUnvalidAddress = useMemo(() => editMode && updateContributor.road?.length > 35, [updateContributor.road])
    const isUnvalidAddressComplement = useMemo(() => editMode && updateContributor.addressComplement?.length > 35, [updateContributor.addressComplement])

    const dispatch = useDispatch()

    const getCodePacage = () => (
        codesSandre.find((c) => c.field === SANDRE.INTERVENANTS_TYPE_CODIFICATION && c.name === 'PACAGE')?.code
    )

    const getPacage = () => (
        updateContributor.link_codificationContributors?.find(c => c?.codificationType === getCodePacage())?.codification ?? ''
    )

    const preleveur = useMemo(() => {
        return { ...contributor, ...updateContributor } || new DtoIntervenant({})
    }, [contributor, updateContributor])

    const saveNewContributor = () => {
        const originalPreleveur = new DtoIntervenantDeclaration({
            ...contributor,
            idContributor: contributor.id,
            surveyId: declaration.idSurvey,
        })
        const updatedPreleveur = new DtoIntervenantDeclaration({
            ...preleveur,
            idContributor: preleveur.id,
            surveyId: declaration.idSurvey,
        })
        if (!deepEqual(originalPreleveur, updatedPreleveur)) {
            dispatch(AgriAction.updateDeclarationContributor(
                declaration.idSurvey,
                new DtoIntervenantDeclaration({
                    ...preleveur,
                    idContributor: preleveur.id,
                    surveyId: declaration.idSurvey,
                    contactType: 1,
                }),
            ))
        }
    }

    useEffect(() => {
        const updatedContributorFound = declarationContributors.find(
            (updateC) =>
                contributor.id === updateC.idContributor &&
                    declaration.idSurvey === updateC.surveyId &&
                    updateC.contactType === DECLARATION_CONTACT_TYPE.CONTRIBUTOR
        )
        const newContributor = { ...updateContributor, ...updatedContributorFound, pacage: getPacage() }
        setUpdateContributor(newContributor)
        setCommune(citiesIndex[Number(newContributor?.cityCode)])
    }, [declarationContributors])

    const citiesFiltered = useMemo(() => (sortBy(cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(String(updateContributor.postalCode)))), 'name')), [updateContributor.postalCode, cities])
    const handleCommuneChange = (value) => {
        const newCommune = citiesIndex[value]
        setCommune(newCommune)
        if (newCommune?.link_postalCode && newCommune?.link_postalCode.length) {
            setUpdateContributor({ ...updateContributor, postalCode: newCommune.link_postalCode[0], cityCode: String(value) })
        } else {
            setUpdateContributor({ ...updateContributor, cityCode: String(value) })
        }
    }

    const handleZIPCodeChange = (value) => {
        setUpdateContributor({ ...updateContributor, postalCode: value })
        setCommune('')
    }

    const handlePhoneChange = (newPhoneNumber) => {
        if (newPhoneNumber.length < 11 || newPhoneNumber.length < updateContributor.mobile.length) {
            setUpdateContributor({ ...updateContributor, mobile: newPhoneNumber })
        }
    }

    return (
        <Grid container item sx={{ height: 'auto', rowGap: '2vh' }}>
            {editMode &&
                <Grid
                    sx={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.15px',
                        color: mainColor
                    }}
                >
                    {i18n.pleaseCheckGivenInfos}
                </Grid>
            }
            <Grid
                item
                xs={12}
                container
                alignItems='flex-start'
                justifyContent='space-between'
                fontSize={14}
            >
                <Grid item xs={4.25}>
                    <InputRow
                        noFullWidth
                        disabled={!editMode}
                        item
                        id={i18n.address}
                        label={i18n.address}
                        type='text'
                        value={updateContributor?.road || ''}
                        onChange={(event) => setUpdateContributor({ ...updateContributor, road: event.target.value })}
                        variant='outlined'
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: mainColor,
                            },
                        }}
                        error={isUnvalidAddress}
                        helperText={isUnvalidAddress ? i18n.tooLongAddress : ''}
                    />
                </Grid>
                <Grid item xs={1.75}>
                    <InputRow
                        noFullWidth
                        disabled={!editMode}
                        item
                        id={i18n.postalCode}
                        label={i18n.postalCode}
                        type='code'
                        InputProps={{ inputProps: { min: 0, max: 99999 } }}
                        value={updateContributor?.postalCode || ''}
                        onChange={(event) => handleZIPCodeChange(event.target.value) }
                        variant='outlined'
                        required
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: mainColor,
                            },
                        }}
                        error={isUnvalidZIPCode}
                        helperText={isUnvalidZIPCode ? i18n.unvalidZIPCode : ''}
                    />
                </Grid>
                <Grid item xs={2.75}>
                    <Autocomplete
                        disabled={!editMode}
                        id={i18n.city}
                        options={citiesFiltered}
                        getOptionLabel={(option) =>
                            option.name ? option.name : ''
                        }
                        value={commune || ''}
                        onChange={(e, value) => handleCommuneChange(value ? value.code : '')}
                        noOptionsText='Pas de correspondance'
                        renderInput={(params) => (
                            <InputRow
                                {...params}
                                noFullWidth
                                disabled={!editMode}
                                item
                                label={i18n.city}
                                variant='outlined'
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: mainColor,
                                    },
                                }}
                                required
                                error={isUnvalidCity}
                                helperText={isUnvalidCity ? i18n.unvalidCity : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2.75}>
                    <InputRow
                        noFullWidth
                        disabled={!editMode}
                        item
                        id={i18n.addressComplement}
                        label={i18n.addressComplement}
                        type='text'
                        value={updateContributor?.addressComplement || ''}
                        onChange={(event) => setUpdateContributor({ ...updateContributor, addressComplement: event.target.value })}
                        variant='outlined'
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: mainColor,
                            },
                        }}
                        error={isUnvalidAddressComplement}
                        helperText={isUnvalidAddressComplement ? i18n.tooLongAddressComplement : ''}
                    />
                </Grid>
                <Grid item xs={4.25}>
                    <InputRow
                        noFullWidth
                        disabled={!editMode}
                        item
                        id={i18n.phoneTel}
                        label={i18n.phoneTel}
                        type='text'
                        value={editMode ? updateContributor?.mobile || '' : formatPhone(updateContributor?.mobile)}
                        onChange={(event) => handlePhoneChange(event.target.value)}
                        variant='outlined'
                        error={isUnvalidPhone}
                        helperText={isUnvalidPhone ? i18n.unvalidPhone : ''}
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: mainColor,
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={4.5 + 0.5/3}>
                    <InputRow
                        noFullWidth
                        disabled={!editMode}
                        item
                        id={i18n.email}
                        label={i18n.email}
                        type='text'
                        value={updateContributor?.email || ''}
                        onChange={(event) => setUpdateContributor({ ...updateContributor, email: event.target.value })}
                        variant='outlined'
                        error={isUnvalidEmail}
                        helperText={isUnvalidEmail ? i18n.unvalidAddress : ''}
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: mainColor,
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={2.25} />
                <Grid item xs={1/3} />
                <Grid item xs={4.25}>
                    <InputRow
                        required
                        noFullWidth
                        disabled={!editMode}
                        item
                        id={i18n.siret}
                        label={i18n.siret}
                        type='text'
                        value={updateContributor?.siret || ''}
                        onChange={(event) => setUpdateContributor({ ...updateContributor, siret: event.target.value })}
                        variant='outlined'
                        error={isUnvalidSiret}
                        helperText={isUnvalidSiret ? i18n.unvalidSiret : ''}
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: mainColor,
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={4.5 + 0.5/3}>
                    <InputRow
                        noFullWidth
                        disabled
                        item
                        id={i18n.pacage}
                        label={i18n.pacage}
                        type='text'
                        value={updateContributor?.pacage || ''}
                        variant='outlined'
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: mainColor,
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={2.25} />
                <Grid item xs={1/3} />
            </Grid>
            <Grid item sx={{ fontSize: '14px', lineHeight: '16px', color: darkTextColor }}>{i18n.requiredField}</Grid>
            <Grid container item alignItems='center' justifyContent='center'>
                {
                    editMode ?
                        <MainButton
                            noFullWidth
                            sx={{ width: 'auto' }}
                            onClick={ () => {
                                saveNewContributor()
                                setEditMode(false)
                                dispatch(AgriAction.updateDeclaration({ ...declaration, lastStep: DECLA_LAST_STEP.CONTACTS_STEP, statusCode: DECLARATION_STATUS.ONGOING })).then(() => {
                                    dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation))
                                })
                            }}
                            disabled={isUnvalidCity || isUnvalidEmail || isUnvalidPhone || isUnvalidSiret || isUnvalidZIPCode || isUnvalidAddress || isUnvalidAddressComplement}
                        >
                            {i18n.validFileInfos}
                        </MainButton>
                        :
                        (declaration.statusCode !== DECLARATION_STATUS.SENT &&
                            <MainButton noFullWidth sx={{ width: 'auto' }} onClick={ () => {
                                setEditMode(true)
                                dispatch(AgriAction.updateDeclaration({ ...declaration, lastStep: DECLA_LAST_STEP.PERSONNAL_INFO_STEP, statusCode: DECLARATION_STATUS.STARTING })).then(() => {
                                    dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation)).then(() => {
                                        setValidationStep(false)
                                    })
                                })
                            }}
                            >
                                {i18n.changeFileInfos}
                            </MainButton>
                        )
                }
            </Grid>
        </Grid>
    )
}

InfosValidation.propTypes = {
    readMode: PropTypes.bool,
    setValidationStep: PropTypes.func,
}

export default InfosValidation